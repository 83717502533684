html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

h1{
  text-align: center;
  margin-Bottom: 20px;
  margin: 5%;
}

body {
  background-color: #1D2D4A;
  color: #FFFFFF;
}

.header-text {
  text-decoration: underline;
  position: absolute;
  top: 5px;
  left: 10px;
  font-style: italic;
  color: #FFFFFF;
  font-size: 100%;
  z-index: 2;
}

.title-container {
  position: relative;
  margin-bottom: 20px;
  overflow: visible;
}

.title {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 0px;
  position: relative;
}

.title::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 1px;
  background-color: #FFFFFF;
  vertical-align: middle;
  margin: 0 10px;
}

.overlay-text {
  position: absolute;
  left: 0;
  top: -300%;
  color: #CFD3D8;
  font-size: 300%;
  pointer-events: none;
  z-index: -1;
  opacity: 0.5;
  text-align: left;
  margin-left: calc(50% - 50vw);
}

.grid-container {
  padding: 5%;
  overflow: hidden;
}

.card {
  width: 100%;
  margin: auto;
  cursor: pointer;
  border-radius: 16px !important;
  overflow: hidden;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5)!important;
  background: linear-gradient(to bottom right,#4f4f4f,#969696,#c1c1c1,#e4e4e4,#ffffff,#ffffff)!important;
}

.card img {
  object-fit: cover;
  height: 170px;
}

.icon-animation {
  animation: pulse 1.5s infinite, blink 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
}

.monalisa {
  width: 80%;
  height: auto;
}

.azami {
  width: 80%;
  height: auto;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.react-image {
  position: absolute;
  top: 18%;
  left: 42%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: 8%;
  animation: spin 2s linear infinite;
}

.react-image.second {
  animation-delay: 0.1s;
  left: 50%;
}

.react-image.third {
  top: 21%;
  left: 46%;
}

.react-image.fourth {
  animation-delay: 0.1s;
  top: 20.5%;
  left: 52.5%;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

.scroll-indicator {
  position: absolute;
  bottom: 10px;
  top: 70%;
  left: 50%;
  transform: translateX(-30%);
  width: 2px;
  height: 50px;
  background-color: #FFFFFF;
  animation: bounce 1s infinite;
  z-index: 10;
}

.scroll-indicator::after {
  content: '';
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #FFFFFF;
}

@keyframes bounce {
  0%, 100% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(10px); }
}

.manga-carousel {
  display: flex;
  white-space: nowrap;
  margin-top: 20px;
  margin-bottom: 40px;
  width: calc(150px * 6 + 20px * 5);
  padding: 10px;
  animation: scroll 20s linear infinite;
}

.manga-image {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (min-width: 1024px) {
  .monalisa{
    width:30%;
  }
  .azami{
    width: 30%;
  }
  .react-image {
    width: 2.8%;
    left: 47%;
  }
  .react-image.third {
    left: 48.5%;
  }
  .react-image.fourth {
    left: 51%;
  }
  .overlay-text{
    font-size: 620%;
  }
  .title{
    font-size: 200%;
  }
  .accordion-container {
    width: 50%;
    margin: auto !important;
  }
  .manga-carousel {
    width: calc(250px * 6 + 40px * 5);
  }
  .manga-image {
    width: 250px;
    margin-right: 40px;
  }
}